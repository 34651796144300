<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12">GST Data</div>
    </div>
    <div class="search-voter-section-outer" style="height: calc(100vh - 141px);">
        <div class="layout-content-section">
            <div class="form-box-outer">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group">
                            <label for="formgstinput" class="form-label">GST No.
                                <span class="text-danger">*</span></label>
                            <input type="text" v-model="gstinnumber" class="form-control text-uppercase"
                                id="formgstinput" :disabled="inputdisabled" placeholder="Enter GST No."
                                autocomplete="off" maxlength="15" />
                            <span class="custom-error" v-if="errgst">
                                {{ errgst }}
                            </span>
                            <span class="custom-error" v-if="errmsg">
                                {{ errmsg }}
                            </span>
                        </div>
                    </div>
                    <div class="
                            col-lg-4 col-md-4 col-12
                          " v-if="!sendotpbtnhide">
                        <div class="custom-form-group" style="margin-top: 26px;">
                            <button type="button" class="header-btn-outer btn btn-primary"
                                @click="getgstcarddetails(gstinnumber)" :disabled="errStatus || gstinnumber.length == 0
                                    " :style="{ width: '104px' }">
                                <span v-if="!showloader">Get Details</span>
                                <div class="
                                  spinner-border
                                  text-light
                                  custom-spinner-loader-btn
                                " role="status" v-if="showloader">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-5 col-12 text-end" v-if="clearbtnflag">
                        <div class="custom-form-group" style="margin-top: 26px;">
                            <button type="button" class="btn custom-view-detail-btn" @click="searchvoterclearbtn()">Clear
                                All
                            </button>
                        </div>
                    </div>
                </div>
                <div class="search-tab-outer-scroll" :style="{ height: '339px' }" v-if="mobiledetailshowflag">
                    <div class="voter-exit-box-outer mb-3">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Aadhaar Validation</label>
                                    <div class="from-label-value">
                                        {{ GSTTabDetails.data.aadhaar_validation ? GSTTabDetails.data.aadhaar_validation : "N/A" }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Aadhaar Validation Date</label>
                                    <div class="from-label-value">
                                        {{ format_date(GSTTabDetails.data.aadhaar_validation_date) }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Address</label>
                                    <div class="from-label-value">
                                        {{ GSTTabDetails.data.address ? GSTTabDetails.data.address : "N/A" }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Business Name</label>
                                    <div class="from-label-value">
                                        {{ GSTTabDetails.data.business_name ? GSTTabDetails.data.business_name : "N/A" }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Center Jurisdiction</label>
                                    <div class="from-label-value">
                                        {{ GSTTabDetails.data.center_jurisdiction ? GSTTabDetails.data.center_jurisdiction : "N/A" }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Constitution of Business</label>
                                    <div class="from-label-value">
                                        {{ GSTTabDetails.data.constitution_of_business ? GSTTabDetails.data.constitution_of_business : "N/A" }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Date of Cancellation</label>
                                    <div class="from-label-value">
                                        {{ format_date(GSTTabDetails.data.date_of_cancellation) }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Date of Registration</label>
                                    <div class="from-label-value">
                                        {{ format_date(GSTTabDetails.data.date_of_registration) }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Field Visit Conducted</label>
                                    <div class="from-label-value">
                                        {{ GSTTabDetails.data.field_visit_conducted ? GSTTabDetails.data.field_visit_conducted : "N/A" }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">GSTIN</label>
                                    <div class="from-label-value">
                                        {{ GSTTabDetails.data.gstin ? GSTTabDetails.data.gstin : "N/A" }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">GSTIN Status</label>
                                    <div class="from-label-value">
                                        {{ GSTTabDetails.data.gstin_status ? GSTTabDetails.data.gstin_status : "N/A" }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Legal Name</label>
                                    <div class="from-label-value">
                                        {{ GSTTabDetails.data.legal_name ? GSTTabDetails.data.legal_name : "N/A" }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Nature of Core Business Activity Code</label>
                                    <div class="from-label-value">
                                        {{ GSTTabDetails.data.nature_of_core_business_activity_code ? GSTTabDetails.data.nature_of_core_business_activity_code : "N/A" }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Nature of Core Business Activity Description</label>
                                    <div class="from-label-value">
                                        {{ GSTTabDetails.data.nature_of_core_business_activity_description ? GSTTabDetails.data.nature_of_core_business_activity_description : "N/A" }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Pan Number</label>
                                    <div class="from-label-value">
                                        {{ GSTTabDetails.data.pan_number ? GSTTabDetails.data.pan_number : "N/A" }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">State Jurisdiction</label>
                                    <div class="from-label-value">
                                        {{ GSTTabDetails.data.state_jurisdiction ? GSTTabDetails.data.state_jurisdiction : "N/A" }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Taxpayer Type</label>
                                    <div class="from-label-value">
                                        {{ GSTTabDetails.data.taxpayer_type ? GSTTabDetails.data.taxpayer_type : "N/A" }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-12" v-if="GSTTabDetails.data.address_details.length != 0">
                                <div class="row"
                                    v-for="(addressdetails, index) in GSTTabDetails.data.address_details"
                                    :key="addressdetails"
                                    v-bind:class="{ dividerrowhide: index == GSTTabDetails.data.address_details.length - 1 }">
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Address Details</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ addressdetails }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-12" v-if="GSTTabDetails.data.filing_frequency.length != 0">
                                <div class="row"
                                    v-for="(fillingfrequency, index) in GSTTabDetails.data.filing_frequency"
                                    :key="fillingfrequency"
                                    v-bind:class="{ dividerrowhide: index == GSTTabDetails.data.filing_frequency.length - 1 }">
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Filing Frequency</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ fillingfrequency }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-12" v-if="GSTTabDetails.data.filing_status.length != 0">
                                <div class="row"
                                    v-for="(fillingstatus, index) in GSTTabDetails.data.filing_status"
                                    :key="fillingstatus"
                                    v-bind:class="{ dividerrowhide: index == GSTTabDetails.data.filing_status.length - 1 }">
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Filing Status</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ fillingstatus }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-12" v-if="GSTTabDetails.data.hsn_info.length != 0">
                                <div class="row"
                                    v-for="(hsninfo, index) in GSTTabDetails.data.hsn_info"
                                    :key="hsninfo"
                                    v-bind:class="{ dividerrowhide: index == GSTTabDetails.data.hsn_info.length - 1 }">
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Hsn Info</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ hsninfo }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-12" v-if="GSTTabDetails.data.nature_bus_activities.length != 0">
                                <div class="row"
                                    v-for="(busactivity, index) in GSTTabDetails.data.nature_bus_activities"
                                    :key="busactivity"
                                    v-bind:class="{ dividerrowhide: index == GSTTabDetails.data.nature_bus_activities.length - 1 }">
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Nature Bus Activities</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ busactivity }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="voter-exit-box-outer pb-3" v-if="nodatafoundflag">
                    <div>No records found.</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from "../../service/ApiService";
import useValidate from "@vuelidate/core";
import moment from 'moment';
export default {
    data() {
        return {
            v$: useValidate(),
            gstinnumber: '',
            errgst: '',
            errmsg: '',
            errStatus: false,
            showloader: false,
            GSTTabDetails: "",
            mobiledetailshowflag: false,
            inputdisabled: false,
            sendotpbtnhide: false,
            nodatafoundflag: false,
            clearbtnflag: false,
        };
    },
    validations() {
        return {
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    watch: {
        gstinnumber(gstinnumber) {
            this.errmsg = "";
            this.validategstinNumber(gstinnumber);
            if (gstinnumber.length == 0) {
                this.errgst = "";
                this.errStatus = false;
            }
        },
    },
    mounted() {
    },
    methods: {
        getgstcarddetails(e) {
            this.showloader = true;
            this.ApiService.getGSTDetails({ gst_number: e }).then((data) => {
                if (data.status == true) {
                    this.GSTTabDetails = JSON.parse(data.data.gst_response);
                    this.mobiledetailshowflag = true;
                    this.showloader = false;
                    this.inputdisabled = true;
                    this.sendotpbtnhide = true;
                    this.clearbtnflag = true;
                } else {
                    this.mobiledetailshowflag = false;
                    this.showloader = false;
                    this.nodatafoundflag = true;
                    this.clearbtnflag = true;
                }
            });
        },
        validategstinNumber(val) {
            this.errStatus = false;
            if (!this.validGST(val)) {
                this.errgst = "Please enter valid GST number";
                this.errStatus = true;
            }
            if (this.errStatus == false) {
                this.errgst = "";
                return true;
            }
        },

        validGST: function (e) {
            var re = /[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
            return re.test(e);
        },
        format_date(value) {
            if (value) {
                return moment(String(value)).format("DD/MM/YYYY");
            } else {
                return 'N/A';
            }
        },
        searchvoterclearbtn() {
            this.gstinnumber = '';
            this.errgst = '';
            this.errmsg = '';
            this.errStatus = false;
            this.showloader = false;
            this.GSTTabDetails = '';
            this.mobiledetailshowflag = false;
            this.inputdisabled = false;
            this.sendotpbtnhide = false;
            this.nodatafoundflag = false;
            this.clearbtnflag = false;
        },
    },
};
</script>
<style scoped>
.form-box-outer {
    padding: 16px 16px;
    margin-bottom: 15px;
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
}

.search-voter-section-outer {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    height: 512px;
}

.search-voter-section-outer::-webkit-scrollbar {
    display: block;
}

.search-voter-section-outer::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-voter-section-outer::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.search-tab-outer-scroll {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

.search-tab-outer-scroll::-webkit-scrollbar {
    display: block;
}

.search-tab-outer-scroll::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-tab-outer-scroll::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.voter-exit-box-outer {
    padding: 16px 16px 0;
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
}

.customer-label-group {
    margin-bottom: 20px;
}

.customer-label-group .form-label {
    font-family: "AcuminPro-Regular";
    font-size: 13px;
    letter-spacing: 0px;
    color: #4a5463;
    line-height: 13px;
    margin-bottom: 6px;
}

.customer-label-group .from-label-value {
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
}
</style>